import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseForm } from '@shared/classes';
import { ViewModeService } from '@shared/services/view-mode.service';
import { IssuesDataService } from '../../services/data/issues-data.service';

@Component({
  selector: 'app-issue-change-status-form',
  templateUrl: './issue-change-status-form.component.html',
  styleUrls: ['./issue-change-status-form.component.scss'],
})
export class IssueChangeStatusFormComponent extends BaseForm<any> implements OnInit {
  constructor(
    private requestService: IssuesDataService,

    public viewModeService: ViewModeService
  ) {
    super(viewModeService, 'ISSUE');
  }
  getData() {
    // return this.viewModeService.viewMode == "create"
    //     ? this.getDataKeyValueFormat()
    //     : this.getChangedFormValues().updateItems;
    return { ...this.formGroup.getRawValue() };
  }

  setData(data: any) {
    this.formGroup.patchValue({ ...data });
    this.data = data;

    this.formGroup?.controls?.sourceType.valueChanges.subscribe((data) => {
      this.formGroup?.controls?.sourceCode.setValue(null);
    });
  }
  ngOnInit(): void {}

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      CLOSE_ISSUES: new FormControl(null),
    });
  }
}
